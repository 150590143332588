import * as React from "react";
import * as queryString from "query-string";
import type { JsonValue } from "../services/persistence";
import useStickyState from "../hooks/useStickyState";

const initialState: JsonValue = {};

const rightAnswers: {
  [key: string]: string;
} = {
  A: "lorem",
  B: "ipsum",
};

const rounds = Object.keys(rightAnswers);

// markup
/** @ts-ignore */
const IndexPage = ({ location }) => {
  const [score, setScore] = useStickyState("score", initialState);
  const [error, setError] = React.useState({ error: false, message: "" });

  React.useEffect(() => {
    const { round, answer } = queryString.parse(location.search);
    if (typeof round !== "string" || !rightAnswers[round]) {
      setError({ error: true, message: "URL params not valid." });
      return;
    }
    if (answer !== rightAnswers[round]) {
      setError({ error: true, message: "Unfortunately, wrong answer." });

      setScore({
        ...score,
        [round]: false,
      });
      return;
    }
    setScore({
      ...score,
      [round]: true,
    });
  }, []);
  return (
    <main>
      <title>YouTube Quiz</title>
      <h1>Persistence Test</h1>
      <h3>Score</h3>
      {!!error.error && <p>{error.message}</p>}
      <ul>
        {rounds.map((key) => {
          let answer = "—";
          if (typeof score[key] === "boolean") {
            answer = score[key] ? "✅" : "❌";
          }
          return (
            <li key={key}>
              <b>{key}:</b>
              <span style={{ marginLeft: "20px" }}>{answer}</span>
            </li>
          );
        })}
      </ul>
      <button onClick={() => setScore(initialState)}>Reset score</button>
    </main>
  );
};

export default IndexPage;
